import React, { useMemo, useState } from 'react';
import { ReactComponent as ProgrammingSvg } from 'assets/svg/programming.svg';
import { ReactComponent as SketchingSvg } from 'assets/svg/sketching.svg';
import { ReactComponent as ShoppingSvg } from 'assets/svg/shopping.svg';
import { SectionHeaderText } from '../components/SectionHeaderText';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

// ServiceItem Component
const ServiceItem = ({ icon: Icon, title, children, reverse, className }) => {
    return (
        <div
            className={clsx(
                'flex flex-col items-center justify-start py-1 md:flex-row md:p-4',
                reverse && 'md:justify-end',
                className
            )}
        >
            <div
                className={clsx(
                    'relative mb-2 h-40 md:mb-0 md:h-auto md:w-2/12',
                    reverse ? 'md:order-2 md:ml-4' : 'md:mr-4'
                )}
            >
                <Icon className="h-full" />
            </div>
            <div className="flex flex-1 flex-col md:mb-2">
                <span className="mb-2 text-xl font-bold">{title}</span>
                <span className="text-gray-600">{children}</span>
            </div>
        </div>
    );
};

const ServicesSection = ({ scrollId }) => {
    const PAGE_COUNT = 3;

    const Switcher = ({ direction, onClick }) => {
        const side = useMemo(() => direction === 'left', [direction]);
        const iconClassName = clsx('h-7 w-7 text-white', side ? 'pr-0.5' : 'pl-0.5');
        const Icon = side ? ChevronLeftIcon : ChevronRightIcon;

        return (
            <button
                className={clsx(
                    `absolute top-1/2 rounded-full bg-calypso-400 p-1.5 shadow transition
                    duration-100 focus:bg-picton-blue-600 focus:outline-0 md:hidden`,
                    side ? 'ml-3' : 'mr-3',
                    side ? 'left-0' : 'right-0'
                )}
                onClick={onClick}
            >
                <Icon className={iconClassName} />
            </button>
        );
    };

    const [servicePage, setServicePage] = useState(0);

    const switchServicePage = (direction) => {
        if (direction === 'left') {
            setServicePage((prev) => (prev - 1 + PAGE_COUNT) % PAGE_COUNT);
        } else {
            setServicePage((prev) => (prev + 1) % PAGE_COUNT);
        }
    };
    const scrollLeft = () => switchServicePage('left');
    const scrollRight = () => switchServicePage('right');

    return (
        <div
            className="relative flex w-full flex-col justify-center self-center px-5
                md:px-4 lg:max-w-[80%] lg:px-0"
            id={scrollId}
        >
            <div className="h-16" />
            <div className="mb-8 flex items-center justify-center">
                <SectionHeaderText>Meie teenused</SectionHeaderText>
            </div>
            <div className="overflow-x-visible px-12 md:px-0">
                <div
                    className="grid grid-cols-[repeat(3,_100%)]
                        gap-x-[calc((100vw_-_100%)_/_2)] transition-transform md:flex
                        md:w-auto md:translate-x-0 md:flex-col md:justify-normal
                        md:gap-x-0 md:gap-y-2 md:overflow-x-auto"
                    style={{
                        // Simplified equation, translates to -(100% + gap) * servicePage
                        transform: `translateX(calc(-50 * ${servicePage} * (1% + 1vw)))`,
                    }}
                >
                    <ServiceItem icon={ProgrammingSvg} title="Veebiarendus">
                        Oleme kogenud arendajad, kes on valmis igasugusteks
                        väljakutseteks. Oskame teha nii taskukohase <em>online</em>
                        -portfoolio kui ka spetsiifiliste nõudmistega keeruka
                        digiplatvormi. Pöördu oma ideega julgelt meie poole.
                    </ServiceItem>

                    <ServiceItem icon={SketchingSvg} title="Disainitööd" reverse>
                        Muidugi oskame disainida veebilehti, kuid sellega meie oskused ei
                        piirdu. Soovitame tellida meilt ka logo või lausa kogu
                        stiiliraamat, kuna sümbiootiline disain aitab kulusid kokku hoida,
                        seejuures kvaliteeti tõstes.
                    </ServiceItem>

                    <ServiceItem icon={ShoppingSvg} title="Veebipoed">
                        E-kaubandus on keeruline ja nõuab palju tööd, et kõik sujuks. Oma
                        pikaajalise kogemusega saame aidata sinu tooted internetti viia,
                        olgu tegu alustava väikeettevõttega või suurema ettevõttega, mis
                        on end juba füüsilises maailmas tõestanud.
                    </ServiceItem>
                </div>
                <Switcher direction="left" onClick={scrollLeft} />
                <Switcher direction="right" onClick={scrollRight} />
            </div>
        </div>
    );
};

export default ServicesSection;
