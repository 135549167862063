import React from 'react';
import Spinner from 'components/Spinner';

const TabContent = React.memo(({ tab, index, iframeStyle, handleIframeLoad }) => (
    <div
        style={{ display: tab.selected ? 'block' : 'none', ...iframeStyle }}
        className="absolute inset-0 z-20"
    >
        {tab.loading && (
            <div
                className="absolute left-0 top-0 z-10 flex h-full w-full items-center
                    justify-center"
            >
                <Spinner />
            </div>
        )}
        <iframe
            title={tab.title}
            className="h-full w-full"
            src={tab.viewed ? tab.url : undefined}
            onLoad={() => handleIframeLoad(index)}
        />
    </div>
));

export default TabContent;
