import React, { useEffect, useState, useCallback } from 'react';
import HeaderPart from './HeaderPart';
import AnimationPart from './AnimationPart';

const OpenerSection = ({ scrollId }) => {
    const [realHeight, setRealHeight] = useState(window.innerHeight);
    const [openerStyle, setOpenerStyle] = useState({});
    const [supportsSVH, setSupportsSVH] = useState(false);

    // Utility to check CSS feature support for 'svh'
    const checkSVHSupport = useCallback(() => {
        const div = document.createElement('div');
        div.style.height = '100svh';
        document.body.appendChild(div);
        const isSupported = getComputedStyle(div).height !== '';
        document.body.removeChild(div);
        setSupportsSVH(isSupported);
    }, []);

    // Updates real height based on the window size
    const updateRealHeight = useCallback(() => {
        setRealHeight(window.innerHeight);
    }, []);

    // Updates opener style based on screen size and svh support
    const updateOpenerStyle = useCallback(() => {
        if (!supportsSVH && window.matchMedia('(min-width: 768px)').matches) {
            setOpenerStyle({
                '--kooder-opener-height': `calc(${realHeight}px - 6.25rem - 1.5rem)`,
            });
        }
    }, [realHeight, supportsSVH]);

    useEffect(() => {
        checkSVHSupport();
        const deviceWidth = window.matchMedia('(min-width: 1024px)');

        if (!deviceWidth.matches) {
            window.addEventListener('resize', updateRealHeight);
            updateRealHeight();
        }

        const smDeviceWidth = window.matchMedia('(min-width: 768px)');
        updateOpenerStyle();
        smDeviceWidth.addEventListener('change', updateOpenerStyle);

        return () => {
            window.removeEventListener('resize', updateRealHeight);
            smDeviceWidth.removeEventListener('change', updateOpenerStyle);
        };
    }, [updateOpenerStyle, updateRealHeight, checkSVHSupport]);

    return (
        <div
            style={openerStyle}
            id={scrollId}
            className="kooder-opener-height mb-2 flex flex-col md:mb-0"
        >
            <HeaderPart />
            <AnimationPart />
        </div>
    );
};

export default OpenerSection;
