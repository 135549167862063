import React from 'react';
import clsx from 'clsx';

const ChatBubble = ({ content, side = 'left', visible, avatar }) => (
    <div
        className={clsx(
            'flex flex-row gap-3 transition-all',
            visible ? 'opacity-100' : 'opacity-0'
        )}
    >
        {side === 'left' ? (
            <>
                <div className="flex items-center rounded-lg bg-gray-100 px-4 py-3">
                    {content}
                </div>
                <div className="min-w-1/3 flex-grow" />
            </>
        ) : (
            <>
                <div className="min-w-1/3 flex-grow" />
                <div className="flex items-center rounded-lg bg-gray-100 px-4 py-3">
                    {content}
                </div>
                {avatar && (
                    <div
                        className="relative flex h-12 w-12 items-center justify-center
                            rounded-full border border-gray-200 bg-white"
                    >
                        <img className="h-2/3 w-2/3" src={avatar} alt="Avatar" />
                    </div>
                )}
            </>
        )}
    </div>
);

export default ChatBubble;
