import React, { useEffect, useState, useId } from 'react';
import clsx from 'clsx';

const ShowcaseSlider = ({ IconElement, title, text, expanded }) => {
    const [expandedState, setExpandedState] = useState(false);

    useEffect(() => {
        setExpandedState(expanded);
    }, [expanded]);

    const id = useId();

    /**
     * Inner component, which returns either an invisible spacer or the content.
     * The spacer is necessary to make sure expansion doesn't change scroll offsets.
     *
     * @param {("spacer"|"content")} role - The role of the component.
     * @returns {JSX.Element}
     */
    const Inner = ({ role }) => {
        const isSpacer = role === 'spacer';

        return (
            <div
                className={clsx(
                    `flex h-min flex-col items-center rounded-lg border border-gray-200
                    px-5 py-2 shadow-md backdrop-blur`,
                    {
                        'pointer-events-none invisible': isSpacer,
                        'absolute left-0 w-full': !isSpacer,
                    }
                )}
            >
                <div className="flex h-14 items-center justify-center">
                    <IconElement style={{ fill: `url(#${id})` }} className="h-9 w-auto" />
                </div>
                <span className="text-center text-lg font-bold">{title}</span>
                <span
                    className={clsx('w-full text-center md:transition-all', {
                        'md:max-h-0 md:opacity-0': !expandedState && !isSpacer,
                        'max-h-[1000px] opacity-100': expandedState || isSpacer,
                        'pointer-events-none': isSpacer,
                    })}
                >
                    {text}
                </span>
            </div>
        );
    };

    return (
        <div
            className="group relative flex h-min flex-col items-center bg-white/15
                md:w-1/4"
        >
            <svg width="0" height="0" className="absolute">
                <linearGradient id={id} x1="100%" y1="100%" x2="0%" y2="0%">
                    <stop stopColor="#06b6d4" offset="0%" />
                    <stop stopColor="#3b82f6" offset="100%" />
                </linearGradient>
            </svg>
            <Inner role="spacer" />
            <Inner role="content" />
        </div>
    );
};

export default ShowcaseSlider;
