import React, { forwardRef } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

const AnimatedNavBar = forwardRef(({ animationStage }, ref) => (
    <div
        ref={ref}
        className="relative flex h-16 flex-row items-center rounded-lg border
            border-gray-200 bg-white/30 p-3 shadow-md backdrop-blur"
    >
        <div
            className={clsx(
                'relative h-full w-10 rounded-lg bg-blue-200 transition md:w-10',
                `after:absolute after:left-0 after:top-0 after:h-full after:w-full
                after:rounded-lg after:transition-opacity`,
                {
                    'after:bg-picton-blue-400 after:bg-opacity-40 after:opacity-100':
                        animationStage >= 8,
                    'after:opacity-0': animationStage < 8,
                }
            )}
        />
        <div
            className={clsx(
                'ml-auto hidden h-2/3 w-16 rounded-lg transition-all xl:block',
                {
                    'bg-picton-blue-400 bg-opacity-40': animationStage >= 8,
                    'bg-blue-100': animationStage < 8,
                }
            )}
        />
        <div
            className={clsx('ml-3 hidden h-2/3 w-12 rounded-lg transition-all xl:block', {
                'bg-picton-blue-400 bg-opacity-40': animationStage >= 8,
                'bg-blue-100': animationStage < 8,
            })}
        />
        <div
            className="ml-auto flex h-full w-min items-center justify-center rounded-lg
                border border-gray-200 p-2 xl:hidden"
        >
            <svg
                className="h-6 w-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                ></path>
            </svg>
        </div>
        <div className="absolute left-0 top-0 h-full w-full p-3">
            <div className="relative h-full w-full">
                <div
                    className={clsx(
                        'absolute left-1/2 h-full w-full transition-all duration-200',
                        animationStage > 2 ? 'translate-x-0' : 'translate-x-[50%]'
                    )}
                >
                    <div
                        className={clsx(
                            `flex h-full w-min flex-row items-center justify-center
                            rounded-lg border p-2 duration-200`,
                            animationStage > 2 ? '-translate-x-1/2' : '-translate-x-full',
                            {
                                'border-picton-blue-400':
                                    animationStage > 4 && animationStage >= 8,
                                'border-blue-500':
                                    animationStage > 4 && animationStage < 8,
                                'border-gray-200': animationStage <= 4,
                            }
                        )}
                    >
                        <div
                            className={clsx(
                                `overflow-x-hidden whitespace-nowrap text-gray-400
                                transition-all delay-500`,
                                animationStage > 3
                                    ? 'mr-1 max-w-[2000px]'
                                    : 'mr-0 max-w-0'
                            )}
                        >
                            Otsi toodet
                        </div>
                        <MagnifyingGlassIcon className="h-full" />
                    </div>
                </div>
            </div>
        </div>
        <div
            className={clsx(
                `flex h-full w-min items-center justify-center rounded-lg border
                border-gray-200 opacity-0 transition-all duration-200`,
                animationStage > 2 ? 'ml-0 max-w-0 p-0' : 'ml-3 p-2'
            )}
        >
            <MagnifyingGlassIcon className="h-full" />
        </div>
    </div>
));

export default AnimatedNavBar;
