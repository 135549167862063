import clsx from 'clsx';
import React from 'react';

export function SectionHeaderText({ children }) {
    return (
        <span
            className={clsx(
                'bg-clip-text text-4xl font-bold text-transparent',
                '-mb-2 bg-gradient-to-l from-mariner-500 to-picton-blue-400 pb-2'
            )}
        >
            {children}
        </span>
    );
}
