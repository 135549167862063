import React from 'react';
import { EnvelopeIcon, MapPinIcon } from '@heroicons/react/16/solid';
import { PhoneIcon } from '@heroicons/react/16/solid';
import { ReactComponent as KooderWhite } from 'assets/svg/kooder-white.svg';

function Footer() {
    return (
        <footer className="bg-calypso-800">
            <div className="container relative mx-auto text-white">
                <div
                    className="mx-auto flex flex-col items-center justify-between px-5
                        pb-1 pt-4 md:flex-row md:justify-start md:px-4 lg:max-w-[80%]
                        lg:px-0"
                >
                    <div
                        className="mb-3 flex flex-col items-center md:mb-0 md:w-7/12
                            md:items-start md:pl-0.5"
                    >
                        <KooderWhite className="h-12 w-auto" />
                        <span className="text-xl font-bold">OÜ Kooderid</span>
                        <span className="">Disainerid, kes võtavad sind kuulda</span>
                    </div>
                    <div
                        className="flex flex-col items-center md:mt-3 md:items-start
                            md:self-start"
                    >
                        <span className="mb-1 hidden text-xl font-bold md:inline-block">
                            Kontakt
                        </span>
                        <div className="text-sm">
                            <PhoneIcon className="mr-2 inline w-5 text-mariner-300" />
                            <a
                                href="tel:+372 5455 2205"
                                className="text-mariner-100 hover:underline"
                            >
                                +372 5455 2205
                            </a>
                        </div>
                        <div className="mt-1.5 text-sm">
                            <EnvelopeIcon
                                className="mr-2 inline w-5 pb-0.5 text-mariner-300"
                            />
                            <a
                                href="mailto:info@kooder.ee"
                                className="text-mariner-100 hover:underline"
                            >
                                info@kooder.ee
                            </a>
                        </div>
                        <div className="mt-1.5 text-sm">
                            <MapPinIcon
                                className="-mt-0.5 mb-0.5 mr-2 inline w-5 text-mariner-300"
                            />
                            <span>Tallinn, Eesti</span>
                        </div>
                    </div>
                </div>
                <div
                    className="mx-4 my-2 h-0 border-b border-b-mariner-400 md:my-4
                        lg:mx-auto lg:my-5 lg:w-[80%]"
                />
                <div className="mx-auto mb-4 mt-3 text-center md:mt-0 lg:max-w-[80%]">
                    <span className="text-sm text-mariner-100">
                        © {new Date().getFullYear()} Kooder OÜ. Kõik õigused kaitstud.
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
