import React from 'react';
import clsx from 'clsx';

const BrowserTabGraphic = ({
    title,
    selected,
    onClick,
    rightDivider,
    onMouseEnter,
    onMouseLeave,
    favicon,
}) => {
    return (
        <div
            className={clsx(
                `group relative flex h-full cursor-pointer items-center justify-start
                rounded-t-lg px-4`,
                `after:absolute after:left-0 after:top-[15%] after:z-30 after:h-[70%]
                after:w-full after:transition-opacity`,
                {
                    'z-20 bg-white': selected,
                    'z-10 bg-white bg-opacity-0 transition hover:z-40 hover:bg-opacity-100':
                        !selected,
                    'after:opacity-100 hover:after:opacity-0': rightDivider,
                    'after:opacity-0': !rightDivider,
                    'after:border-r after:border-r-mariner-800':
                        rightDivider && !selected,
                }
            )}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {/* Bottom right outside corner */}
            <div
                className="pointer-events-none absolute left-full top-0 h-full w-full
                    overflow-clip"
            >
                <div
                    className={clsx(
                        `z-20 h-full w-full rounded-bl-lg shadow-tabright
                        transition-opacity`,
                        {
                            'opacity-0 group-hover:opacity-100': !selected,
                        }
                    )}
                />
            </div>
            {/* Bottom left outside corner */}
            <div
                className="pointer-events-none absolute right-full top-0 h-full w-full
                    overflow-clip"
            >
                <div
                    className={clsx(
                        `z-20 h-full w-full rounded-br-lg shadow-tableft
                        transition-opacity`,
                        {
                            'opacity-0 group-hover:opacity-100': !selected,
                        }
                    )}
                />
            </div>
            {/* Favicon */}
            <img
                src={favicon}
                alt={`${title} icon`}
                className="h-4 w-4 object-cover md:mr-2"
            />
            <span className="hidden md:inline">{title}</span>
        </div>
    );
};

export default BrowserTabGraphic;
