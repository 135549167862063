import React, { useEffect, useState } from 'react';
import { SectionHeaderText } from '../components/SectionHeaderText';
import clsx from 'clsx';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

/**
 * Toast component to display success or error messages
 *
 * @param {Object} props
 * @param {string} props.message
 * @param {("success"|"error")} props.message_type
 * @param {function} props.onDisappeared
 * @returns {JSX.Element|null}
 */
const Toast = ({ message, message_type, onDisappeared }) => {
    const [disappearing, setDisappearing] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisappearing(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            className="fixed bottom-0 left-0 right-0 z-50 mb-20 flex flex-row
                justify-center"
        >
            <div
                className={clsx(
                    `rounded-xl border border-white px-4 py-3 text-white shadow-xl
                    transition-opacity duration-1000`,
                    {
                        'bg-green-700': message_type === 'success',
                        'bg-pink-700': message_type === 'error',
                        'pointer-events-none opacity-0': disappearing,
                        'opacity-100': !disappearing,
                    }
                )}
                onTransitionEnd={onDisappeared}
            >
                <p>{message}</p>
            </div>
        </div>
    );
};

const ContactSection = ({ scrollId }) => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    const validEmail = (email) => {
        // Simple email validation regex
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear the error message for the field being edited
        if (errors[name]) {
            setErrors({ ...errors, [name]: '' });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let validationErrors = {};

        if (!formData.name.trim()) {
            validationErrors.name = 'Palun sisesta oma nimi';
        }

        if (!formData.email.trim()) {
            validationErrors.email = 'Palun sisesta oma e-mail';
        } else if (!validEmail(formData.email)) {
            validationErrors.email = 'Palun sisesta korrektne e-mail';
        }

        if (!formData.message.trim()) {
            validationErrors.message = 'Palun sisesta sõnum';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors({});
        }

        fetch(`${API_ENDPOINT}/contact.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status !== 'success') {
                    throw new Error('Failed to send message');
                }
                setShowSuccess(true);
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });
            })
            .catch((error) => {
                setShowError(true);
                console.error(error);
            });
    };

    return (
        <div
            className="flex w-full flex-col justify-center self-center px-5 md:px-4
                lg:max-w-[80%] lg:px-0"
            id={scrollId}
        >
            {showSuccess && (
                <Toast
                    message="Sinu sõnum on saadetud!"
                    message_type="success"
                    onDisappeared={() => {
                        setShowSuccess(false);
                    }}
                />
            )}
            {showError && (
                <Toast
                    message="Sõnumi saatmisel tekkis viga. Palun proovi uuesti."
                    message_type="error"
                    onDisappeared={() => {
                        setShowError(false);
                    }}
                />
            )}
            <div className="h-16" />
            <div className="mb-8 flex items-center justify-center">
                <SectionHeaderText>Võta meiega ühendust</SectionHeaderText>
            </div>
            <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-4"
            >
                <div className="col-span-1 flex flex-col">
                    <label className="mb-2 text-calypso-700">Sinu nimi</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className={clsx(
                            'rounded border p-2',
                            errors.name ? 'border-red-500' : 'border-gray-300'
                        )}
                    />
                    {errors.name && (
                        <p className="mt-1 text-sm text-red-500">{errors.name}</p>
                    )}
                </div>
                <div className="col-span-1 flex flex-col">
                    <label className="mb-2 text-calypso-700">Sinu e-mail</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className={clsx(
                            'rounded border p-2',
                            errors.email ? 'border-red-500' : 'border-gray-300'
                        )}
                    />
                    {errors.email && (
                        <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                    )}
                </div>
                <div className="col-span-2 flex flex-col">
                    <label className="mb-2 text-calypso-700">Sõnum</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className={clsx(
                            'rounded border p-2',
                            errors.message ? 'border-red-500' : 'border-gray-300'
                        )}
                        rows="5"
                    />
                    {errors.message && (
                        <p className="mt-1 text-sm text-red-500">{errors.message}</p>
                    )}
                </div>
                <div className="col-span-2 flex justify-center">
                    <button
                        type="submit"
                        className="focus:bg-pos-200 rounded-lg bg-gradient-to-r
                            from-picton-blue-500 via-mariner-500 to-calypso-500
                            bg-size-200 bg-pos-0 p-3 px-4 text-sm font-semibold text-white
                            transition-all hover:bg-pos-100"
                    >
                        Saada
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ContactSection;
