import React from 'react';

const HeaderPart = () => {
    return (
        <div
            className="mb-10 mt-[7.5rem] flex flex-col items-center justify-center
                space-y-3 sm:mt-32 md:flex-grow md:space-y-4 lg:mb-0 lg:mt-20
                lg:max-h-[30vh] lg:min-h-[20vh] xl:min-h-0 xl:py-10"
        >
            <div
                className="bg-gradient-to-r from-picton-blue-500 via-mariner-500
                    to-calypso-400 bg-clip-text px-5 text-center align-middle font-raleway
                    text-[13vw] font-black leading-none text-transparent md:px-0
                    md:text-6xl"
            >
                Disainerid, kes võtavad sind kuulda.
            </div>
            <span className="px-5 text-center text-xl md:px-0">
                Meie jaoks on tähtsaim viia töö läbi toetudes igal sammul kliendi
                soovidele ja vajadustele.
            </span>
        </div>
    );
};

export default HeaderPart;
