import React from 'react';
import Endrik from 'assets/images/endrik.webp';
import Hans from 'assets/images/hans.webp';
import { SectionHeaderText } from '../components/SectionHeaderText';
import { EnvelopeIcon } from '@heroicons/react/16/solid';

const TeamSection = ({ scrollId }) => {
    return (
        <div
            className="flex w-full flex-col justify-center self-center px-5 md:px-4
                lg:max-w-[80%] lg:px-0"
            id={scrollId}
        >
            <div className="h-16" />
            <div className="mb-8 flex items-center justify-center">
                <SectionHeaderText>Meie tiim</SectionHeaderText>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-4">
                <div
                    className="flex flex-col items-center justify-start gap-2 px-2 py-1
                        md:p-2 xl:flex-row xl:p-4"
                >
                    <div
                        className="mb-3 overflow-clip rounded-full shadow-md sm:w-6/12
                            xl:mb-0 xl:mr-4 xl:w-4/12"
                    >
                        <img src={Endrik} alt="Endrik Einberg" />
                    </div>
                    <div className="mb-2 flex flex-1 flex-col">
                        <span className="mb-2 text-calypso-700">
                            Tegevjuht ja arendaja
                        </span>
                        <span className="mb-2 text-xl font-bold">Endrik Einberg</span>
                        <span className="mb-3 text-gray-600">
                            Endrik on tegelenud veebiarendusega juba aastaid ning on
                            valmis ka kõige keerukamateks väljakutseteks. Just tema
                            klõbistab kõige rohkem klaviatuuril ning on meie tiimi kogenum
                            IT-spetsialist.
                        </span>
                        <span>
                            <EnvelopeIcon
                                className="mb-0.5 mr-2 inline w-6 text-calypso-700"
                            />
                            <a href="mailto:endrik@kooder.ee">endrik@kooder.ee</a>
                        </span>
                    </div>
                </div>
                <div
                    className="flex flex-col items-center justify-start gap-2 px-2 py-1
                        md:p-2 xl:flex-row xl:p-4"
                >
                    <div
                        className="mb-3 overflow-clip rounded-full shadow-md sm:w-6/12
                            xl:mb-0 xl:mr-4 xl:w-4/12"
                    >
                        <img src={Hans} alt="Hans Herman Männik" />
                    </div>
                    <div className="mb-2 flex flex-1 flex-col">
                        <span className="mb-2 text-calypso-700">
                            Müügijuht ja disainer
                        </span>
                        <span className="mb-2 text-xl font-bold">Hans Herman Männik</span>
                        <span className="mb-3 text-gray-600">
                            Hans teeb kõik selleks, et kliendi soovid saaksid täidetud
                            kvaliteetselt ja stiilselt. Sellegipoolest on ka tema tugev
                            programmeerija, kes leiab klientide pakilisematele muredele
                            alati kiire lahenduse.
                        </span>
                        <span>
                            <EnvelopeIcon
                                className="mb-0.5 mr-2 inline w-6 text-calypso-700"
                            />
                            <a href="mailto:hans@kooder.ee">hans@kooder.ee</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSection;
