import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

const NavButton = ({ onClick, children }) => (
    <button
        onClick={onClick}
        className="focus:bg-pos-200 rounded-lg bg-gradient-to-r from-picton-blue-500
            via-mariner-500 to-calypso-500 bg-size-200 bg-pos-0 p-3 px-4 text-sm
            font-semibold text-white transition-all hover:bg-pos-100"
    >
        {children}
    </button>
);

const NavLink = ({ href, children }) => (
    <li>
        <a
            href={href}
            className="block rounded-lg py-2 pl-3 pr-4 text-gray-700 hover:bg-calypso-100
                hover:bg-opacity-75 hover:text-calypso-900 md:border-0 md:p-0
                md:hover:bg-transparent md:hover:text-calypso-700"
        >
            {children}
        </a>
    </li>
);

/**
 * Navigation component
 *
 * @param {JSX.Element} children
 * @param {string} logoHref
 * @returns {JSX.Element}
 */
const Nav = ({ logoHref = '/#', children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [resizing, setResizing] = useState(false);

    useEffect(() => {
        let timer;
        const handleResize = () => {
            if (timer) {
                clearTimeout(timer);
            } else {
                setResizing(true);
            }
            timer = setTimeout(() => {
                setResizing(false);
            }, 300);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const buttons = [];
    const links = [];

    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type === NavButton) {
                buttons.push(child);
            } else if (child.type === NavLink) {
                links.push(child);
            }
        }
    });

    return (
        <div className="sticky left-0 top-0 z-50 h-0 w-full overflow-visible">
            <nav className="absolute -top-full left-0 z-50 w-full p-4">
                <div
                    className="rounded-lg border border-gray-200 bg-white/60 px-2 py-2.5
                        shadow-md backdrop-blur md:px-4"
                >
                    <div
                        className="container mx-auto flex max-w-full flex-wrap
                            items-center justify-between"
                    >
                        <a
                            href={logoHref}
                            className="flex items-center font-poppins font-semibold"
                        >
                            <img
                                src="/kooder.svg"
                                className="ml-3 mr-3 h-10 max-w-full sm:h-12 md:ml-0"
                                alt="Kooder Logo"
                            />
                            <span
                                className="hidden self-center whitespace-nowrap text-xl
                                    font-semibold text-gray-700 md:inline"
                            >
                                Kooder
                            </span>
                        </a>
                        <div className="flex md:order-2">
                            {buttons}
                            <button
                                type="button"
                                className="ml-2 inline-flex items-center rounded-lg
                                    bg-gray-100 p-2 text-sm text-gray-500
                                    hover:bg-gray-200 focus:outline-none focus:ring-2
                                    focus:ring-gray-200 md:hidden"
                                onClick={() => setMenuOpen(!menuOpen)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <svg
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div
                            className={clsx(
                                `w-full origin-top duration-200 md:ml-auto md:mr-5
                                md:w-auto`,
                                {
                                    'transition-all': !resizing,
                                    'transition-none': resizing,
                                    'scale-y-1 max-h-[40vh]': menuOpen,
                                    [`max-h-0 scale-y-0 opacity-0 md:max-h-full
                                    md:scale-y-100 md:opacity-100`]: !menuOpen,
                                }
                            )}
                        >
                            <ul
                                className="mt-3 flex flex-col gap-4 rounded-lg p-2 md:mt-0
                                    md:flex-row md:gap-0 md:space-x-8 md:border-0
                                    md:bg-transparent md:text-sm md:font-medium"
                            >
                                {links}
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Nav;
export { NavButton, NavLink };
