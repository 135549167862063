import { Routes, Route } from 'react-router-dom';
import Home from 'pages/Home/Home';

function App() {
    return (
        <div className="relative w-full overflow-x-hidden">
            <Routes>
                <Route path="/" element={<Home />} />
            </Routes>
        </div>
    );
}

export default App;
