import React, { useReducer, useEffect, useRef, useCallback, useState } from 'react';
import BrowserTabGraphic from 'pages/Home/sections/PortfolioSection/BrowserTabGraphic';
import useIsInViewport from 'hooks/useIsInViewport';
import TabContent from './TabContent';
import { SectionHeaderText } from '../../components/SectionHeaderText';

const DEFAULT_SCALE_FACTOR = 0.5;
const WIDTH_CLAMP_FACTOR = 1.15;
const MAX_IFRAME_HEIGHT_RATIO = 0.6;

const TARGET_PERCENT_PER_MIN_WIDTH = [
    { minWidth: 0, percent: 0.8 },
    { minWidth: 640, percent: 0.7 },
    { minWidth: 768, percent: 0.6 },
    { minWidth: 1024, percent: 0.6 },
    { minWidth: 1280, percent: 0.5 },
    { minWidth: 1536, percent: 0.5 },
];

const initialBrowserTabs = [
    {
        title: 'Hoog',
        selected: true,
        rightDivider: true,
        url: 'https://hoog.ee/',
        viewed: false,
        loading: true,
    },
    {
        title: 'Solarmen',
        selected: false,
        rightDivider: false,
        url: 'https://solarmen.ee/',
        viewed: false,
        loading: true,
    },
    {
        title: 'GoSwim',
        selected: false,
        rightDivider: false,
        url: 'https://goswim.ee/',
        viewed: false,
        loading: true,
    },
];

const getFloatPropertyValue = (element, propertyName) => {
    const style = getComputedStyle(element);
    const value = style.getPropertyValue(propertyName);
    return parseFloat(value.replace('px', ''));
};

const browserTabsReducer = (state, action) => {
    switch (action.type) {
        case 'SELECT_TAB': {
            const { tabIndex, browserInView } = action.payload;
            return state.map((tab, index) => {
                const isSelected = index === tabIndex;
                return {
                    ...tab,
                    selected: isSelected,
                    loading: isSelected && !tab.viewed ? true : tab.loading,
                    rightDivider: index !== tabIndex - 1,
                    viewed: browserInView && isSelected ? true : tab.viewed,
                };
            });
        }
        case 'HANDLE_IFRAME_LOAD': {
            const { index } = action.payload;
            return state.map((tab, idx) =>
                idx === index ? { ...tab, loading: false } : tab
            );
        }
        case 'UPDATE_VIEWED': {
            const { browserInView } = action.payload;
            if (!browserInView) return state;
            return state.map((tab) => (tab.selected ? { ...tab, viewed: true } : tab));
        }
        case 'SET_LOADING_FALSE': {
            const { title } = action.payload;
            return state.map((tab) =>
                tab.title === title ? { ...tab, loading: false } : tab
            );
        }
        case 'MOUSE_ENTER': {
            const { index } = action.payload;
            return state.map((tab, idx) => {
                if (idx === index - 1) {
                    return { ...tab, rightDivider: false };
                }
                return tab;
            });
        }
        case 'MOUSE_LEAVE': {
            const { index } = action.payload;
            return state.map((tab, idx) => {
                if (
                    idx === index - 1 &&
                    !state[index - 1].selected &&
                    !state[index].selected
                ) {
                    return { ...tab, rightDivider: true };
                }
                return tab;
            });
        }
        default:
            return state;
    }
};

const PortfolioSection = ({ scrollId }) => {
    const [browserTabs, dispatch] = useReducer(browserTabsReducer, initialBrowserTabs);

    const browserRef = useRef();
    const iframeContainerRef = useRef();
    const browserInView = useIsInViewport(browserRef);

    const [browserContainerStyle, setBrowserContainerStyle] = useState({
        width: '80%',
    });
    const [iframeStyle, setIframeStyle] = useState({});
    const [miniBrowserStyle, setMiniBrowserStyle] = useState({ height: '60vh' });

    const resizeHandler = useCallback(() => {
        const viewportWidth = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
        );
        const viewportHeight = Math.min(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        const aspectRatio = viewportWidth / viewportHeight;

        const borderLeftWidth = getFloatPropertyValue(
            browserRef.current,
            'border-left-width'
        );
        const borderRightWidth = getFloatPropertyValue(
            browserRef.current,
            'border-right-width'
        );

        const getTargetWidthAndScaleFactor = (targetPercent, scaleFactor) => {
            const targetWidth = Math.round(viewportWidth * targetPercent);
            let iframeWidth = targetWidth / scaleFactor;
            const maxIframeWidth = viewportWidth * WIDTH_CLAMP_FACTOR;

            if (iframeWidth > maxIframeWidth) {
                iframeWidth = maxIframeWidth;
                scaleFactor = targetWidth / iframeWidth;
            }

            return { targetWidth, scaleFactor };
        };

        const calculateDimensions = (initialScaleFactor) => {
            let scaleFactor = initialScaleFactor;
            let targetWidth = 0;

            for (const { minWidth, percent } of TARGET_PERCENT_PER_MIN_WIDTH) {
                if (viewportWidth >= minWidth) {
                    const result = getTargetWidthAndScaleFactor(
                        percent,
                        initialScaleFactor
                    );
                    targetWidth = result.targetWidth;
                    scaleFactor = result.scaleFactor;
                } else {
                    break;
                }
            }

            return { targetWidth, scaleFactor };
        };

        const { targetWidth, scaleFactor } = calculateDimensions(DEFAULT_SCALE_FACTOR);

        setBrowserContainerStyle({
            width: `${(targetWidth + borderLeftWidth + borderRightWidth).toFixed(1)}px`,
        });

        let targetHeight = targetWidth / aspectRatio;
        const maxIframeHeight = viewportHeight * MAX_IFRAME_HEIGHT_RATIO;
        if (targetHeight > maxIframeHeight) {
            targetHeight = maxIframeHeight;
        }
        const iframeHeight = targetHeight / scaleFactor;

        setIframeStyle({
            transform: `scale(${scaleFactor.toFixed(3)})`,
            transformOrigin: 'top left',
            width: `${(targetWidth / scaleFactor).toFixed(1)}px`,
            height: `${iframeHeight.toFixed(1)}px`,
        });

        setMiniBrowserStyle({
            height: `${(iframeHeight * scaleFactor).toFixed(1)}px`,
        });
    }, []);

    useEffect(() => {
        resizeHandler();
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [resizeHandler]);

    const selectBrowserTab = useCallback(
        (tabIndex) => {
            dispatch({
                type: 'SELECT_TAB',
                payload: { tabIndex, browserInView },
            });
        },
        [browserInView]
    );

    const handleIframeLoad = useCallback((index) => {
        dispatch({ type: 'HANDLE_IFRAME_LOAD', payload: { index } });
    }, []);

    const handleMouseEnter = useCallback((index) => {
        dispatch({ type: 'MOUSE_ENTER', payload: { index } });
    }, []);

    const handleMouseLeave = useCallback((index) => {
        dispatch({ type: 'MOUSE_LEAVE', payload: { index } });
    }, []);

    useEffect(() => {
        dispatch({ type: 'UPDATE_VIEWED', payload: { browserInView } });
    }, [browserInView]);

    useEffect(() => {
        const selectedTab = browserTabs.find((tab) => tab.selected);
        if (!selectedTab) return;
        const timer = setTimeout(() => {
            dispatch({
                type: 'SET_LOADING_FALSE',
                payload: { title: selectedTab.title },
            });
        }, 5000);

        return () => clearTimeout(timer);
    }, [browserTabs]);

    return (
        <div
            className="flex w-full flex-col justify-center self-center px-5 md:px-4
                lg:max-w-[80%] lg:px-0"
            id={scrollId}
        >
            <div className="h-16" />
            <div className="flex items-center justify-center pb-6">
                <SectionHeaderText>Meie portfoolio</SectionHeaderText>
            </div>
            <div
                className="relative flex w-full flex-col self-center overflow-hidden
                    rounded-xl border bg-white shadow-md"
                style={browserContainerStyle}
                ref={browserRef}
            >
                <div className="flex h-12 flex-row bg-mariner-200 px-2 pt-2">
                    {browserTabs.map((tab, index) => (
                        <BrowserTabGraphic
                            key={index}
                            title={tab.title}
                            selected={tab.selected}
                            rightDivider={tab.rightDivider}
                            favicon={`https://s2.googleusercontent.com/s2/favicons?domain=${tab.url}&sz=64`}
                            onClick={() => selectBrowserTab(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        />
                    ))}
                    <div className="ml-auto mr-1 flex flex-row items-center gap-2 pb-2">
                        <div
                            className="h-3 w-3 rounded-full bg-red-400
                                shadow-browserWindowButton"
                        />
                        <div
                            className="h-3 w-3 rounded-full bg-yellow-300
                                shadow-browserWindowButton"
                        />
                        <div
                            className="h-3 w-3 rounded-full bg-green-400
                                shadow-browserWindowButton"
                        />
                    </div>
                </div>
                <div
                    className="relative flex"
                    style={miniBrowserStyle}
                    ref={iframeContainerRef}
                >
                    {browserTabs.map(
                        (tab, index) =>
                            (tab.viewed || tab.selected) && (
                                <TabContent
                                    key={tab.url}
                                    tab={tab}
                                    index={index}
                                    iframeStyle={iframeStyle}
                                    handleIframeLoad={handleIframeLoad}
                                />
                            )
                    )}
                </div>
            </div>
        </div>
    );
};

export default PortfolioSection;
