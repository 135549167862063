import React, { useState, useEffect, useRef } from 'react';
import useOnScreen from 'hooks/useOnScreen';
import ChatBubble from '../../components/ChatBubble';
import AnimatedNavBar from '../../components/AnimatedNavBar';

const ANIMATION_STAGES = [
    { stage: 1, delay: 800 },
    { stage: 2, delay: 2000 },
    { stage: 3, delay: 2500 },
    { stage: 4, delay: 2800 },
    { stage: 5, delay: 3600 },
    { stage: 6, delay: 4600 },
    { stage: 7, delay: 5600 },
    { stage: 8, delay: 6200 },
];

const AnimationPart = () => {
    const [animationStart, setAnimationStart] = useState(false);
    const [animationStage, setAnimationStage] = useState(0);
    const animationRef = useRef();
    const animationVisible = useOnScreen(animationRef);

    useEffect(() => {
        if (animationVisible && !animationStart) {
            setAnimationStart(true);
        }
    }, [animationVisible, animationStart]);

    useEffect(() => {
        if (animationStart) {
            const timers = ANIMATION_STAGES.map(({ stage, delay }) =>
                setTimeout(() => {
                    setAnimationStage(stage);
                }, delay)
            );
            return () => timers.forEach(clearTimeout);
        }
    }, [animationStart]);

    return (
        <div
            className="flex w-full flex-grow flex-wrap items-center justify-center
                self-center px-2 md:flex-grow-0 md:pb-24 lg:max-w-[80%] lg:pb-4"
        >
            <div className="flex w-full flex-col justify-center p-3 md:w-1/2 md:pr-9">
                <span
                    className="bg-gradient-to-r from-mariner-500 to-picton-blue-400
                        bg-clip-text text-xl font-bold text-transparent"
                >
                    Alati valmis muudatusteks
                </span>
                <p className="mb-0 md:text-left">
                    Tahad midagi muuta või uut proovida? Disainiprotsessis on loomulik, et
                    kõik pole esimese korraga veel ideaalselt paigas. Hilisemate ja
                    suuremate muutuste jaoks aitame välja selgitada, mis sul täpsemalt
                    vaja on ja anname kiire hinnapakkumise.
                </p>
            </div>
            <div className="relative flex w-full flex-col md:w-1/2">
                <div className="mb-3 px-3 md:px-2">
                    <AnimatedNavBar animationStage={animationStage} ref={animationRef} />
                </div>
                <div className="flex flex-col gap-3 p-3 md:mb-1 md:p-2">
                    <ChatBubble
                        content="Kas oleks võimalik otsing teha silmapaistvamaks?"
                        side="left"
                        visible={animationStage > 0}
                    />
                    <ChatBubble
                        content="Saab tehtud!"
                        side="right"
                        visible={animationStage > 1}
                        avatar="/kooder.svg"
                    />
                    <ChatBubble
                        content="Uus logo sai valmis, kas saab ruttu lehe ümber muuta?"
                        side="left"
                        visible={animationStage >= 6}
                    />
                    <ChatBubble
                        content="Otse loomulikult!"
                        side="right"
                        visible={animationStage >= 7}
                        avatar="/kooder.svg"
                    />
                </div>
            </div>
        </div>
    );
};

export default AnimationPart;
