import React, { useRef } from 'react';
import Nav, { NavButton, NavLink } from 'components/Nav';
import Footer from 'components/Footer';
import OpenerSection from './sections/OpenerSection';
import SlidersSection from './sections/SlidersSection';
import ServicesSection from './sections/ServicesSection';
import PortfolioSection from './sections/PortfolioSection';
import TeamSection from './sections/TeamSection';
import ContactSection from './sections/ContactSection';

const Home = () => {
    const scrollingContainer = useRef();

    return (
        <div
            style={{ background: "url('/background.png')" }}
            className="relative flex max-h-svh w-full scroll-pt-10 flex-col
                overflow-y-auto overflow-x-hidden scroll-smooth bg-cover"
            ref={scrollingContainer}
        >
            <Nav logoHref="#kodu">
                <NavButton
                    onClick={() => {
                        if (typeof window !== 'undefined')
                            window.location.href = window.location.origin + '/#kontakt';
                    }}
                >
                    Võta ühendust
                </NavButton>
                <NavLink href="#teenused">Teenused</NavLink>
                <NavLink href="#portfoolio">Portfoolio</NavLink>
                <NavLink href="#tiim">Tiim</NavLink>
            </Nav>
            <div className="relative flex w-full flex-grow flex-col">
                <div className="flex w-full flex-row justify-center">
                    <div
                        className="container mx-auto flex flex-col justify-center px-2
                            pb-10 md:px-0"
                    >
                        <OpenerSection scrollId="kodu" />
                        <SlidersSection scrollingContainer={scrollingContainer} />
                        <ServicesSection scrollId="teenused" />
                        <PortfolioSection scrollId="portfoolio" />
                        <TeamSection scrollId="tiim" />
                        <ContactSection scrollId="kontakt" />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
