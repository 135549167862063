import React, { useState, useEffect, useCallback } from 'react';
import {
    LockClosedIcon,
    PaintBrushIcon,
    PencilIcon,
    WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import ShowcaseSlider from '../components/ShowcaseSlider';

const SlidersSection = ({ scrollingContainer }) => {
    const [expandSliders, setExpandSliders] = useState(false);

    const handleScroll = useCallback(
        (scrolledElement) => {
            if (!expandSliders && scrolledElement.scrollTop !== 0) {
                setExpandSliders(true);
            }
        },
        [expandSliders]
    );

    useEffect(() => {
        const scrollElement = scrollingContainer.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', () => handleScroll(scrollElement));
            handleScroll(scrollElement);
        }
    }, [scrollingContainer, handleScroll]);

    return (
        <div
            className="flex w-full flex-row justify-center self-center px-5 md:px-4
                lg:max-w-[80%] lg:px-0"
        >
            <div className="flex flex-grow flex-col gap-4 md:flex-row lg:gap-5">
                <ShowcaseSlider
                    IconElement={PaintBrushIcon}
                    title="Moodne disain"
                    text="Iga veebileht on moodsa disainiga ning ehitatud nullist üles kliendi soovidest lähtudes."
                    expanded={expandSliders}
                />
                <ShowcaseSlider
                    IconElement={LockClosedIcon}
                    title="Turvaline"
                    text="Liigume ajaga kaasas ja teeme kindlaks, et sinu firma leht ei jää kergeks sihtmärgiks."
                    expanded={expandSliders}
                />
                <ShowcaseSlider
                    IconElement={PencilIcon}
                    title="Muudetav sisu"
                    text="Sinu värske restorani hindade uuendamiseks ei lähe kindlasti vaja haridust informaatikas."
                    expanded={expandSliders}
                />
                <ShowcaseSlider
                    IconElement={WrenchScrewdriverIcon}
                    title="Erilahendused"
                    text="Peale disainerite oleme ka usinad programmeerijad, kes on valmis igasugusteks väljakutseteks."
                    expanded={expandSliders}
                />
            </div>
        </div>
    );
};

export default SlidersSection;
